<template>
  <div class="ma-9">
    <HnrButton
        :text="'Thêm mới danh mục'"
        color="primary"
        width="250px"
        @hnrClickEvent="addCategory"
    />
    <v-data-table
        :headers="headers"
        :items="categories"
    >
      <template v-slot:item.url="{ item }">
        <v-img
            :src="item.url"
            width="200px"
            class="my-2"
        ></v-img>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="getDetail(item.id)">
          mdi-lead-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item.id)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {
  GET_LIST_CATEGORY,DELETE_CATEGORY
} from "@/store/category.module";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

export default {
  components: {
    HnrButton: () => import("@/components/elements/hnr-button")
  },
  //list news
  data() {
    return {
      headers: [
        {text: "Tên bài viết", value: "title"},
        {text: "Slug", value: "slug"},
        {text: "Ảnh", value: "url"},
        {text: "Hành động", value: "actions", sortable: false}
      ]
    };
  },

  async created() {
    await this.$store.dispatch(GET_LIST_CATEGORY);
    await this.$store.dispatch(SET_BREADCRUMB, [{ title: "Danh mục",titledad:'Danh mục' }]);
  },

  computed: {
    ...mapGetters({
      categories: "categories"
    })
  },
  methods: {
    addCategory() {
      this.$router.push('/category/create')
    },
    getDetail(id) {
      this.$router.push(`/category/detail/${id}`)
    },
    async deleteItem(id) {
      if (confirm("Bạn muốn xóa sách?")) {
        await this.$store.dispatch(DELETE_CATEGORY,{id : id} ) .then(data =>{
          if (data.data.status){
            this.$toasted.success('Xóa thành công', {
              position: "top-right",
              theme: "toasted-primary",
              duration : 1000
            });
            this.$store.dispatch(GET_LIST_CATEGORY);
          }
        });
      }
    },
  }
};
</script>